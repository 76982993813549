import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../index.css';
import Icon1 from '../../../src/assets/Ugroimages/dashboard/createcase.png';
import Icon2 from '../../../src/assets/Ugroimages/dashboard/wipcases.png';
import Icon3 from '../../../src/assets/Ugroimages/dashboard/opencases.png';
import Icon4 from '../../../src/assets/Ugroimages/dashboard/closedcases.png';
import NextIcon from '../../../src/assets/Ugroimages/dashboard/next.png';
import './style.scss';
import MainHeading from "../../components/DashboardComponent/MainHeading";
import formattedCurrentDate from "../../Helper/formattedCurrentDate";
import { useStores } from "../../stores";
import Loader from "../../Helper/Loader/Loader";
import { GetProjectID } from "../../shared/service/helper";
import CryptoJS from 'crypto-js'

const projectID = GetProjectID();

const Dashboard = (props) => {
    const navigate = useNavigate();
    const { applicantStore } = useStores();
    const { year, month, day, hours, mins, secs } = formattedCurrentDate()

    const [scrollFlag, seTScrollFlag] = useState(true)
    const [allIssuesCount, setAllIssuesCount] = useState(0)
    const [numberOfWIP, setNumberOfWIP] = useState(0)
    const [numberOfOpenCases, setNumberOfOpenCases] = useState(0)
    // const [numberOfReopenCases, setNumberOfReopenCases]=  useState(0)
    const [numberOfClosedCases, setNumberOfClosedCases] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const [agentEmail, setAgentEmail] = useState('');

    let email = sessionStorage.getItem('email')
    var bytes = CryptoJS.AES.decrypt(email, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
    var agentemail = bytes.toString(CryptoJS.enc.Utf8);

    let name = sessionStorage.getItem('name')
    var namebytes = CryptoJS.AES.decrypt(name, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
    var agentName = namebytes.toString(CryptoJS.enc.Utf8);

    let logRecordCount = sessionStorage.getItem('logRecordCount');

    useEffect(() => {
        seTScrollFlag(false)
        setAgentEmail(agentemail)
    }, [scrollFlag])

    useEffect(() => {
        logRecordCount != 1 && getLoginDetails()
        getAllIssues();
    }, [])

    const getLoginDetails = () => {
        let requestData = {
            "name": agentName,
            "email": agentemail,
            "login_status": 1
        }
        applicantStore.saveUserDetails(requestData, (response) => {
            let originalResponse = response?.original?.data
        })
    }

    const getAllIssues = async () => {
        let requestData = {
            "agentId": agentemail
        };
        setIsLoading(true)
        applicantStore.getLsqServiceRequestData(requestData, (response) => {
            
            setAllIssuesCount(response?.total_issues_created)
            setNumberOfClosedCases(response?.closed_cases)
            setNumberOfOpenCases(response?.open_cases)
            setNumberOfWIP(response?.work_in_progress)
            setIsLoading(false)
        }, (error) => {
            console.log("ticket count error ", error);
            setIsLoading(false)
        })
    }

    const handlePendingLaf = () => {
        navigate('/dashboard-card')
    }
    return (
        <div>
            {scrollFlag &&
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }
            {isLoading ? <Loader /> :
                <div className="dashboard-container">
                    <div className="RightArea">
                        <div className="dashboard-area">
                            <MainHeading heading={"Dashboard"} />
                            <div className="container-fluid  form-box">
                                <div className="row">
                                    <div className="col-md-3 col-6">
                                        <div className="icon-box-container">
                                            <img src={Icon1} className="dash-icon" />
                                            <div className="content">
                                                <h3 className="icon-box-heading">{allIssuesCount}</h3>
                                                <h3 className="icon-box-sub-heading">Total Issues Created</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="icon-box-container">
                                            <img src={Icon2} className="dash-icon" />
                                            <div className="content">
                                                <h3 className="icon-box-heading">{numberOfWIP}</h3>
                                                <h3 className="icon-box-sub-heading">Work in Progress</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="icon-box-container">
                                            <img src={Icon3} className="dash-icon" />
                                            <div className="content">
                                                <h3 className="icon-box-heading">{numberOfOpenCases}</h3>
                                                <h3 className="icon-box-sub-heading">Open cases</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                        <div className="icon-box-container">
                                            <img src={Icon4} className="dash-icon" />
                                            <div className="content">
                                                <h3 className="icon-box-heading">{numberOfClosedCases}</h3>
                                                <h3 className="icon-box-sub-heading">Closed cases</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="last-update-text">{`Last updated: ${day}-${month}-${year} , ${hours}:${mins}:${secs}`}</p>
                            </div>

                        </div>

                    </div>
                </div>
            }
        </div>
    );
}

export default Dashboard;