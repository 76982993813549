import React, { useEffect, useState, useRef } from "react";
import "../../index.css";
import "./style.scss";
import CryptoJS from "crypto-js";
import MainHeading from "../../components/DashboardComponent/MainHeading";
import notify from "../../shared/notify";
import Button from "../../components/UI/Button/Button";
import ValidateEmail from "../../Helper/emailValidation";
import { useStores } from '../../stores';
import Loader from "../../Helper/Loader/Loader.js";
import verified from "../../assets/Ugroimages/verified.png"
import remove from "../../assets/Ugroimages/remove.png";
import { GetProjectID } from "../../shared/service/helper";
import encryption from "../../Helper/encryption.js";
let maxUploadFileSzie = process.env.REACT_APP_MAX_FILE_SIZE;
const projectID = GetProjectID();

let searchList = [
  {
    id: 0,
    name: "Select",
    value: ""
  },
  {
    id: 0,
    name: "Loan Account Number(LAN)",
    value: "LAN"
  },
  {
    id: 1,
    name: "PAN",
    value: "PAN"
  },
  {
    id: 2,
    name: "Customer ID",
    value: "CustomerID"
  },
  {
    id: 3,
    name: "Email",
    value: "Email"
  },
  {
    id: 4,
    name: "Mobile",
    value: "Mobile"
  }

];

const ApplicantDetails = (props) => {
  const { applicantStore } = useStores();

  const Input1 = useRef();
  const [customerName, setCustomerName] = useState('')
  const [customerMobile, setCustomerMobile] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')
  const [issueDescription, setIssueDescription] = useState('')
  const [issueSummary, setIssueSummary] = useState('')
  const [scrollFlag, seTScrollFlag] = useState(true)
  const [isRegistered, setIsRegistered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [customerID, setCustomerID] = useState('')
  const [loanAccountNumber, setLoanAccountNumber] = useState([]);
  const [loanAccountData, setLoanAccountData] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedLoanNumber, setSelectedLoanNumber] = useState('')
  const [showVerified, setShowVerified] = useState(false)
  const [productName, setProductName] = useState('')
  const [productScheme, setProductScheme] = useState('')
  const [branch, setBranch] = useState('')
  const [uploadedFileCount, setUploadedFileCount] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFileToJira, setUploadedFileToJira] = useState([]);
  const [rmEmail, setRMEmail] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const [isInternal, setIsInternal] = useState(false)
  const [serviceTypeList, setServiceTyeList] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => { getServiceTypeList() }, [])

  const getServiceTypeList = async () => {
    await applicantStore.getServiceTypeList((response) => {
      if (response?.success) {
        setServiceTyeList(response.data)
      }
      else {
        notify("Failed to fetch Service Type List", 'danger')
      }
    });

  }

  let email = sessionStorage.getItem('email')
  var bytes = CryptoJS.AES.decrypt(email, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
  var agentemail = bytes.toString(CryptoJS.enc.Utf8);

  let is_lsq_active = sessionStorage.getItem('is_lsq_active')
  var lsqbytes = CryptoJS.AES.decrypt(is_lsq_active, `${process.env.REACT_APP_ENCRYPTION_KEY}`);
  var agent_is_lsq_active = lsqbytes.toString(CryptoJS.enc.Utf8);

  useEffect(() => {
    seTScrollFlag(false)
    setRMEmail(agentemail)
  }, [scrollFlag])

  const handleMobileNumber = (value) => {
    if (isNaN(value)) {
      notify('Input must be a number', 'danger');
      return
    }
    setCustomerMobile(value)
  }

  const handleRegisteredCustomer = (value) => {
    {
      if (searchKey === '') {

        notify("Please select Identification", 'danger');
        // setSelectedCustType("")
        return
      }
      if (searchValue === '') {

        notify("Please enter Indentification value", 'danger');
        // setSelectedCustType("")
        return
      }
      // if (customerEmail) {
      //   let checkEmail = ValidateEmail(customerEmail)
      //   if (!checkEmail) {
      //     notify("Please enter valid email id", 'danger');
      //     return;
      //   }
      // }

      setIsLoading(true);
      let payload = {
        fieldName: searchKey,
        fieldValue: searchValue
      }
      var encryptedToken = encryption(payload);
      applicantStore.getCustomerData(encryptedToken, (response) => {
        let originalResponse = response;
        if (!response?.status) {
          notify("User Not Registered", 'danger');
          // setCustomerEmail('')
          setCustomerID('')
          setCustomerName('')
          setCustomerMobile('')
          setLoanAccountNumber([])
          setIssueSummary('')
          setIssueDescription('')
          setIsRegistered(false)
          // setSelectedCustType("0")
          setIsLoading(false);
          setShowVerified(false);
          return;
        }
        let data = originalResponse?.data;
        setCustomerID(data?.customer_id)
        setCustomerName(data?.customer_name)
        setCustomerMobile(data?.mobile)
        setLoanAccountData([...data?.loan_data])
        setCustomerEmail(data?.email)
        let allAccount = [];
        if (data?.loan_data) {
          let allData = data?.loan_data
          allData.forEach(element => {
            allAccount.push(element?.loan_number)
          });
        }
        setLoanAccountNumber(allAccount)
        setIsLoading(false);
        setIsRegistered(true)
        setShowVerified(true);
      })

      // }
      // else {
      //   setSelectedCustType("0")
      //    setIsRegistered(false)
      //    setCustomerName("")
      //    setCustomerMobile("")
      //    setIssueDescription("")
      //    setIssueSummary("")
      //    }

    }
  }

  const handleContinueButton = () => {
    if (customerName === '') {
      notify("Please enter customer name", 'danger');
      return;
    }
    if (customerMobile === '') {
      notify("Please enter customer mobile", 'danger');
      return;
    }
    if (customerEmail === '' || customerEmail === null || customerEmail === undefined) {
      notify("Please enter email", 'danger');
      return;
    }
    if (customerEmail) {
      let checkEmail = ValidateEmail(customerEmail)
      if (!checkEmail) {
        notify("Please enter valid email id", 'danger');
        return;
      }
    }


    if (selectedServiceType === '') {
      notify("Please select Service Type", 'danger')
      return;
    }
    if (issueSummary === '') {
      notify("Please enter summary", 'danger');
      return;
    }
    if (issueDescription === '') {
      notify("Please enter description", 'danger');
      return;
    }

    if (isRegistered) {
      if (customerID === '') {
        notify("Please enter Customer ID", 'danger')
        return;
      }
      if (loanAccountNumber === '') {
        notify("Please select account number", 'danger')
        return;
      }
    }
    setIsLoading(true);
    setIsSubmitting(true);

    if (agent_is_lsq_active) {
      let formData = new FormData();
      formData.append('description', issueDescription)
      formData.append('LAN', loanAccountNumber)
      selectedFiles?.map((item) => {
        formData.append('files[]', item)
      })
      formData.append('Title', issueSummary)
      formData.append('product_name', productName)
      formData.append('service_type', selectedServiceType)
      formData.append('name', customerName)
      formData.append('customerEmail', customerEmail)
      formData.append('EmailAddress', customerEmail)
      formData.append('crm_agent_email', agentemail)
      formData.append('Phone', customerMobile)
      formData.append('portal_name', "Branch CRM")

      applicantStore.submitLsqServiceRequest(formData, (response) => {
        notify(`${response?.data?.message}`, response?.data?.success ? 'success' : 'danger')
        if (response?.data?.success) {
          setTimeout(() => {
            setIsInternal(false);
            setSelectedFiles(null);
            setCustomerName('')
            setCustomerMobile('')
            setCustomerEmail('')
            setIssueDescription('')
            setIssueSummary('')
            setIsLoading(false);
            setSelectedServiceType('')
            setSelectedLoanNumber('');
            setCustomerID('')
            setShowVerified(false)
            setIsRegistered(false)
            setProductName('');
            setProductScheme('');
            setBranch('');
            setUploadedFileToJira([]);
            Input1.current.value = '';
            setUploadedFileCount(0)
          }, 200);
          setTimeout(() => {
            setIsSubmitting(false)
          }, 5000);
        }
      },
        (error) => {
          notify('Failed to create Case. Please try again', 'danger')
          setIsInternal(false);
          setSelectedFiles(null);
          setCustomerName('')
          setCustomerMobile('')
          setCustomerEmail('')
          setIssueDescription('')
          setIssueSummary('')
          setIsLoading(false)
          setSelectedServiceType('')
          setSelectedLoanNumber('');
          setCustomerID('')
          setShowVerified(false)
          setIsRegistered(false)
          setProductName('');
          setProductScheme('');
          setBranch('');
          setUploadedFileToJira([]);
          Input1.current.value = '';
          setUploadedFileCount(0)
          setTimeout(() => {
            setIsSubmitting(false)
          }, 5000);
        })
      setIsLoading(false)
    } else {
      let payload = {
        method: "POST",
        url: "issue",
        data: {
          fields: {
            //service Type
            //description
            description: {
              type: "doc",
              version: 1,
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      text: issueDescription,
                      type: "text"
                    }
                  ]
                }
              ]
            },

            //customer id
            customfield_10073: customerID ? customerID : "",
            //customer name
            customfield_10068: customerName,
            //customer email
            customfield_10070: customerEmail ? customerEmail : "",
            customfield_10069: customerMobile,

            //loan number
            customfield_10071: selectedLoanNumber,
            customfield_10072: { value: selectedServiceType },
            //loan numbers
            customfield_10082: loanAccountNumber,

            //product name
            customfield_10094: isRegistered ? productName : '',

            //branch name
            customfield_10095: isRegistered ? branch : '',

            //product scheme
            customfield_10102: isRegistered ? productScheme : '',

            //isMailRegistered
            customfield_10080: isRegistered ? ["yes"] : ["no"],
            customfield_10074: 'Branch CRM',
            customfield_10010: '114',
            customfield_10083: rmEmail,
            customfield_10201: { value: isInternal ? 'Yes' : 'No' },
            // customfield_10069: homeStore.userModel.data.mobile,
            //customer attachement
            // attachment: serviceStore.serviceMod.input.documents,
            //summary
            summary: issueSummary,
            issuetype: {
              id: "10002"
            },
            project: {
              id: projectID
            },
            assignee: {
              id: "-1"
            }
          }
        }
      }

      applicantStore.submitServiceRequest(payload, uploadedFileToJira, (response) => {
        notify('Case Created successfully', 'success')
        setTimeout(() => {
          let key = response?.key;
          let updatePayload = {
            method: "PUT",
            url: `issue/${key}`,
            data: {
              fields: {
                customfield_10080: isRegistered ? ["yes"] : ["no"]
              }
            }
          };
          applicantStore.updateIsRegisteredMail(updatePayload, (response) => {
            console.log("updated")
          })
          setIsInternal(false);
          setSelectedFiles(null);
          setCustomerName('')
          setCustomerMobile('')
          setCustomerEmail('')
          setIssueDescription('')
          setIssueSummary('')
          setIsLoading(false);
          setSelectedServiceType('')
          setSelectedLoanNumber('');
          setCustomerID('')
          setShowVerified(false)
          setIsRegistered(false)
          setProductName('');
          setProductScheme('');
          setBranch('');
          setUploadedFileToJira([]);
          Input1.current.value = '';
          setUploadedFileCount(0)
          let array = [...selectedFiles];
          array.splice()
          setSelectedFiles(array);
        }, 200);
      },
        (error) => {
          notify('Failed to create Case. Please try again', 'danger')
          setIsInternal(false);
          setSelectedFiles(null);
          setCustomerName('')
          setCustomerMobile('')
          setCustomerEmail('')
          setIssueDescription('')
          setIssueSummary('')
          setIsLoading(false)
          setSelectedServiceType('')
          setSelectedLoanNumber('');
          setCustomerID('')
          setShowVerified(false)
          setIsRegistered(false)
          setProductName('');
          setProductScheme('');
          setBranch('');
          setUploadedFileToJira([]);
          Input1.current.value = '';
          setUploadedFileCount(0)
          let array = [...selectedFiles];
          array.splice()
          setSelectedFiles(array)
        })
    }

  }

  const handleLoanNumber = (value) => {
    setSelectedLoanNumber(value);

    loanAccountData !== undefined && loanAccountData.length !== 0
      && loanAccountData.map((item) => {
        if (item?.loan_number === value) {
          setProductName(item?.product_name)
          setBranch(item?.branch_name)
          setProductScheme(item.product_scheme)
          setCustomerEmail(item?.email)
        }
      })
  }

  const onFileChange = (event) => {
    let fileSize = event.target.files[0].size / 1024 / 1024;
    if (fileSize <= Number(maxUploadFileSzie)) {
      if (uploadedFileCount < 3) {
        let fileName = [];
        if (event.target.files[0]) {
          fileName.push(event.target.files[0].name);
          let array = [...selectedFiles, event.target.files[0]];

          setSelectedFiles([...selectedFiles, event.target.files[0]]);

          let allFormData = [];
          for (let i = 0; i < array.length; i++) {
            let formData = new FormData();
            formData.append("file", array[i]);
            allFormData.push(formData)
          }
          setUploadedFileToJira(allFormData);
          setUploadedFileCount(uploadedFileCount + 1)
          event.target.value = "";

        }
      } else {
        notify("Maximum 3 attachments are allowed", "danger");
      }
    } else {
      notify("Max upload size is 25 mb", "danger");
    }
  };

  const handleRemoveFile = (index) => {
    let array = [...selectedFiles];
    if (index != -1) {
      array.splice(index, 1)
      setSelectedFiles(array)
      setUploadedFileCount(uploadedFileCount - 1)
    }
  }

  return (
    <div>
      {scrollFlag &&
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
      {isLoading ? <Loader /> :
        <div className="applicationdetails-container">
          <div className="RightArea">
            <div className="dashboard-area">
              <MainHeading heading={"Applicant Details"} />
              <div className="container-fluid dash-container">
                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className="form-box">
                        <div className="row form-row">
                          <div className="col">
                            <label className="loan-lable">Select Identification</label>
                            <select
                              className="form-control"
                              id="loanaccountlist"
                              value={searchKey}
                              onChange={(i) => setSearchKey(i.target.value)}
                            >
                              {searchList !== undefined || searchList.length !== 0
                                ? searchList.map((item, index) => {
                                  return (
                                    <option key={item.id} value={item.value}>
                                      {item.name}
                                    </option>
                                  );
                                })
                                : null}
                            </select>
                          </div>
                          <div className="col pb-1">
                            <label className="loan-lable">Enter Value<span className="text-red">*</span></label>
                            <input
                              className="form-control"
                              placeholder={`Enter ${searchKey}`}
                              value={searchValue}
                              onChange={(e) => setSearchValue(e.target.value.replace(/[^a-zA-Z0-9@.-]/g, ""))}
                            />
                          </div>
                          <div className="col single-button">
                            <label className="loan-lable"></label>
                            <Button
                              type={"button"}
                              onClick={handleRegisteredCustomer}
                              buttonStyle="btn--secondary--solid"
                              style={"mob-large-btn"}
                            >
                              Verify Customer
                            </Button>
                            {showVerified && <>
                              <img src={verified} alt="verified image" className="verified-icon" /><span className="verified-color">Registered</span></>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="form-box">
                        <p className="loan-subheading">Details</p>
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <div class="form-check  checkBorder">
                              {console.log("isInternal ", isInternal)}
                              <input
                                key={Math.random()}
                                type="checkbox"
                                defaultChecked={isInternal}
                                onChange={() => setIsInternal(!isInternal)}
                              ></input><span className="checkBtn ml-2">Internal Request</span>
                            </div>
                          </div>
                        </div>
                        <div className="row form-row">
                          {isRegistered && (
                            <>
                              <div className="col">
                                <label className="loan-lable">Customer ID</label>
                                <input
                                  className="form-control"
                                  placeholder="Customer Name"
                                  value={customerID}
                                  onChange={(e) => setCustomerID(e.target.value)}
                                />
                              </div>
                              <div className="col">
                                <label className="loan-lable">Loan Account Number</label>
                                <select
                                  className="form-control"
                                  id="loanaccountlist"
                                  value={selectedLoanNumber}
                                  onChange={(i) => handleLoanNumber(i.target.value)}
                                >
                                  <option defaultValue={""}>
                                    {"Select Loan A/C number"}
                                  </option>
                                  {loanAccountData !== undefined || loanAccountData.length !== 0
                                    ? loanAccountData.map((item, index) => {
                                      return (
                                        <option key={item.loan_number} value={item.loan_number}>
                                          {item.loan_number}
                                        </option>
                                      );
                                    })
                                    : null}
                                </select>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="row form-row">
                          <div className="col">
                            <label className="loan-lable">Customer Name<span className="text-red">*</span></label>
                            <input
                              className="form-control"
                              placeholder="Customer Name"
                              value={customerName}
                              onChange={(e) => setCustomerName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))}
                            />
                          </div>
                          <div className="col">
                            <label className="loan-lable">Mobile Number<span className="text-red">*</span></label>
                            <input
                              className="form-control"
                              placeholder="Mobile Number"
                              maxLength={10}
                              value={customerMobile}
                              onChange={(e) => handleMobileNumber(e.target.value)}
                            />
                          </div>

                        </div>
                        <div className="row form-row">
                          <div className="col-md-6 col-12">
                            <label className="loan-lable">Email<span className="text-red">*</span></label>
                            <input
                              className="form-control input-box"
                              type="text"
                              rows="10"
                              id="Enter Email"
                              placeholder="Enter email address"
                              value={customerEmail}
                              onChange={(e) => setCustomerEmail(e.target.value)}
                            ></input>
                          </div>
                        </div>
                        {isRegistered && <>
                          <div className="row form-row">
                            <div className="col-md-6 col-12">
                              <label className="loan-lable">Product Type</label>
                              <input
                                className="form-control input-box"
                                type="text"
                                rows="10"
                                id="comment"
                                placeholder="Enter product name"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                              ></input>
                            </div>

                            < div className="col">
                              <label className="loan-lable">Branch</label>
                              <input
                                className="form-control input-box"
                                rows="10"
                                id="comment"
                                placeholder="Enter Branch Name"
                                value={branch}
                                onChange={(e) => setBranch(e.target.value)}
                              ></input>
                            </div>

                          </div>
                          <div className="row form-row">
                            <div className="col-md-6 col-12">
                              <label className="loan-lable">Product Scheme</label>
                              <input
                                className="form-control input-box"
                                type="text"
                                rows="10"
                                id="product_scheme"
                                placeholder="Enter product scheme"
                                value={productScheme}
                                onChange={(e) => setProductScheme(e.target.value)}
                              ></input>
                            </div>
                            {/* <div className="col-md-6 col-12">
                              <label className="loan-lable">Email</label>
                              <input
                                className="form-control input-box"
                                type="text"
                                rows="10"
                                id="Enter Email"
                                placeholder="Enter product scheme"
                                value={customerEmail}
                                onChange={(e) => setCustomerEmail(e.target.value)}
                              ></input>
                            </div> */}
                          </div>
                        </>}
                        <div className="row form-row">
                          <div className="col-md-6 col-12">
                            <label className="loan-lable">Service Type<span className="text-red">*</span></label>
                            <select
                              className="form-control"
                              id="loanaccountlist"
                              value={selectedServiceType}
                              onChange={(i) => setSelectedServiceType(i.target.value)}
                            >
                              <option value={""}>
                                {"Select Service Type"}
                              </option>
                              {serviceTypeList !== undefined || serviceTypeList.length !== 0
                                ? serviceTypeList.map((item, index) => {
                                  return (
                                    <option key={item.category_code} value={item.category_name}>
                                      {item.category_name}
                                    </option>
                                  );
                                })
                                : null}
                            </select>
                          </div>
                          <div className="col">
                            <label className="loan-lable">Summary (Short Description)<span className="text-red">*</span></label>
                            <input
                              className="form-control input-box"
                              rows="10"
                              id="comment"
                              placeholder="Enter Issue Summary"
                              value={issueSummary}
                              onChange={(e) => setIssueSummary(e.target.value)}
                            ></input>
                          </div>

                        </div>
                        <div className="row form-row">

                          <div className="col">
                            <label className="loan-lable">Description (Full Description)<span className="text-red">*</span></label>
                            <textarea
                              className="form-control textarea-box"
                              rows="10"
                              id="comment"
                              placeholder="Enter Issue Description"
                              value={issueDescription}
                              onChange={(e) => setIssueDescription(e.target.value)}
                            ></textarea>
                          </div>
                        </div>

                        <div className="row form-row">
                          <input
                            ref={Input1}
                            className="input-file"
                            id="file"
                            type="file"
                            onChange={onFileChange}
                            accept="image/png,image/jpg,.pdf,.docx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          />
                          <label htmlFor="file" className="btn btn-upload">
                            <i
                              className="fa fa-paperclip mr-2"
                              aria-hidden="true"
                            ></i>
                            + Add Attachment{" "}
                            {/* {!serviceStore.uploadedFile.loading && <></>}
                                {serviceStore.uploadedFile.loading && (
                                  <span className="spinner-border text-white ml-2"></span>
                                )} */}
                          </label>
                        </div>
                        <div className="">
                          {selectedFiles != undefined &&
                            selectedFiles != null &&
                            selectedFiles.length >= 1 &&
                            selectedFiles.map(
                              (x, i) => {
                                return (
                                  <div key={i}>
                                    <span>

                                      <small>{x.name}</small>
                                      <img
                                        className="remove-btn"
                                        src={remove}
                                        onClick={() => {
                                          handleRemoveFile(i);
                                        }}
                                      />{" "}
                                      <br />
                                    </span>
                                  </div>
                                );
                              }
                            )}
                        </div>
                        <div className="mt-5">
                          <small className="text-danger mb-2">
                            Note: You can upload only png, jpg, pdf, xlsx,
                            docx.{" "}
                          </small>
                        </div>
                      </div>

                      <div className="row form-row">
                        <div className="col"></div>
                        <div className="col submitButton pt-5">
                          <Button
                            type="button"
                            buttonStyle="btn--secondary--solid"
                            buttonSize="btn-medium"

                            onClick={handleContinueButton}
                            disabled={isSubmitting}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      }
    </div >
  );
};

export default ApplicantDetails;
