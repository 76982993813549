import React from 'react'
import './style.scss'

const STYLES = [
    "btn--primary--solid",
    "btn--secondary--solid",
    "btn--warning--solid",
    "btn--danger--solid",
    "btn--success--solid",
    "btn--primary--outline",
    "btn--warning--outline",
    "btn--danger--outline",
    "btn--success--outline",
    "btn--secondary--outline",
    "mr-10",
    "full-btn"
]

const SIZES=["btn--medium", "btn--large"]

const Button = ({
    children,
    type,
    onClick,
    buttonStyle,
    buttonSize,
    style,
    disabled
}) => {

    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[1];
     

    return (
        <button disabled={disabled || false} onClick={onClick} type={type} className={[`btn-ugro ${checkButtonStyle} ${checkButtonSize} ${style}`]} >{children}</button>
    )
}

export default Button;